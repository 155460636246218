import styled from "@emotion/styled";

const Card = styled.div`
    border: #000 2px solid;
    border-radius: 10px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 25px 40px 25px;
    text-align: center;
`;

export default Card;
import styled from "@emotion/styled";
// Utilidades
import { mediaDevices } from "../../../utilities/mediaDevices.const";

export const GalleryWrapper = styled.section`
    width: 100%;
    height: calc(90vh - 75px);
    position: relative;
    @media ${mediaDevices.tablet} {
        height: 600px;
    }
    @media ${mediaDevices.laptop} {
        height: 700px;
    }
    @media ${mediaDevices.desktop} {
        height: 750px;
    }
`;
import PropTypes from 'prop-types';
import { useState } from 'react';
// Estilos
import { InputGroupAnimated } from "./inputAnimated.styled"

const InputAnimated = ({
  type,
  label,
  value,
  setValue,
  id,
  rows,
  isTextArea,
}) => {
  const [isValueEmpty, setIsValueEmpty] = useState(true);

  return (
    <InputGroupAnimated style={{ height: isTextArea ? "100%" : "" }}>
      {
        isTextArea
          ? <textarea
            id={id}
            rows={rows}
            className={!isValueEmpty ? 'notEmpty' : ''}
            value={value}
            onChange={e => {
              setIsValueEmpty(e.target.value === "");
              setValue(e.target.value);
            }}
          />
          : <input
            id={id}
            type={type}
            className={!isValueEmpty ? 'notEmpty' : ''}
            value={value}
            onChange={e => {
              setIsValueEmpty(e.target.value === "")
              setValue(e.target.value);
            }}
          />
      }
      <label htmlFor={id}>
        {label}
      </label>
    </InputGroupAnimated>
  )
}
InputAnimated.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  rows: PropTypes.string,
  isTextArea: PropTypes.bool,
}
InputAnimated.defaultProps = {
  type: "text",
  isTextArea: false,
  rows: "20",
}
export default InputAnimated
import styled from "@emotion/styled";

export const FeedbackWrapper = styled.div`
    padding: 100px 0 50px;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
`;

export const FeedbackTitle = styled.h2`
    text-align: center;
    margin-bottom: 40px;
`;

export const FeedbackItem = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 25px;
    gap: 20px;
    padding: 40px 60px;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 20%);
    border-radius: 10px;
`
export const FeedbackItemQuote = styled.img`
    width: 80px!important;
`;
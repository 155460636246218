import OwlCarousel from 'react-owl-carousel3';
// Estilos
import {
    FeedbackWrapper,
    FeedbackTitle,
    FeedbackItem,
    FeedbackItemQuote
} from './customersFeedBack.styled'
// Images
import Quote from '../../../images/quote2.png';

const CustomersFeedBack = () => {
    const arrayTestimonials = [
        {
            desc: "Thank you so much for helping me with my move last Friday. I can't thank you enough. As always, the guys were great! I always talk you up to anyone I know that's moving",
            name: "Lynne C. - Las Colinas, TX"
        },
        {
            desc: "Thanks! Job well done, I appreciate it!",
            name: "Randy F. - Tulsa, OK"
        },
        {
            desc: "All three guys were awesome & polite. I appreciate you all getting everything scheduled on short notice. Thanks again. It's a pleasure working with you!",
            name: "Vickie M. - Dallas, TX"
        },
        {
            desc: "Thanks! As always you guys have done a great job for us.",
            name: "Kathy R. - Paradise, TX"
        },
        {
            desc: "As always, thank you for all of your help. Love you guys!",
            name: "Ron B. - Las Colinas, TX"
        },
        {
            desc: "I just wanted to let you know what a great job the moving team did today. All of the guys were very polite and professional. We were very pleased with their efficiency and hard work.",
            name: "Anita - Dallas, TX"
        },
        {
            desc: "Thank you for teaming up with us on this one. It's nice to be able to send pros in to handle the furniture moving when we're dealing with higher end customers",
            name: 'David S. - Fort Worth, TX'
        },
        {
            desc: "Delivery in Dallas went well! Thank you! The guys were very helpful and I really appreciated them.",
            name: "Dorothy K. - Las Colinas, TX"
        },
        {
            desc: "Thank you so much for excellent service. Our move was so much easier since your movers are so professional.",
            name: "Linda D - Dallas, TX"
        },
    ]

    return (
        <FeedbackWrapper>
            <FeedbackTitle>Our customers feedback</FeedbackTitle>
            <OwlCarousel
                className="feedback-slides owl-carousel owl-theme"
                items={1}
                loop={true}
                nav={true}
                dots={false}
                autoplayHoverPause={true}
                autoplay={true}
                margin={0}
                navText={["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"]}
            >
                {
                    arrayTestimonials.map(({ desc, name }, index) => (
                        <FeedbackItem key={index}>
                            <FeedbackItemQuote src={Quote} alt='quote' />
                            <p>{desc}</p>
                            <h4>{name}</h4>
                        </FeedbackItem>
                    ))
                }
            </OwlCarousel>
        </FeedbackWrapper>
    );
}

export default CustomersFeedBack;

// Colores primaris
export const kPrimaryColor = "#4524BC";
export const kPrimaryColorLight0 = "#8E76E5";
export const kPrimaryColorLight3 = "#f3f0ff";
export const kPrimaryColorDark = "#281570";

//Colors de Acentuacion
export const kAccentColor = "#eda425";
export const kAccentColorLight = "#CAAD7D";
export const kAccentColorDark = "#745B2F";

//Colors Extras
export const kSuccessColor = "#00b06f";
export const kDefeatColor = "#E73A54";

//Grises
export const kBgGreyColor = "#fefefe";
export const kLigthGrey = "#E1DCDC";
export const kMediumGrey = "#8F8F8F";
export const kMediumDarkGrey = "#858585";
export const kDarkGrey = "#5c5c5c";

export const kShadowColor = 'rgba(0, 0, 0, 0.15)';
export const kShadowColorDarker = 'rgba(37, 37, 37, 0.4)';
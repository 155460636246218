import styled from '@emotion/styled';
// Colores
import {
    kBgGreyColor,
    kShadowColor,
    kShadowColorDarker,
} from '../../utilities/pallete.const'

const FloatControlButton = styled.button`
    background-color: rgba(151, 151, 151, 0.5);
    width: 30px;
    height: 30px;
    border-radius: 20px;
    @media only screen and (min-width: 1024px){
        width: 42px;
        height: 42px;
        border-radius: 25px;
    }
    display: flex;
    align-items: center;
    color: ${kBgGreyColor};
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    bottom: 50%;
    z-index: 3;
    transition: all 0.25s ease-in-out;
    box-shadow: ${kShadowColor} 0 3px 6px;
    right: 15px;
    padding-left: 6px;
    svg {
        fill: ${kBgGreyColor};
        width: 75%;
        transform: rotateY(180deg);
        margin-left: 4px;   
    }
    &:hover{
        background-color: rgba(227, 227, 227, 0.5);
        transform: scale(1.1);
        box-shadow: ${kShadowColorDarker} 0 3px 6px;
    }
    &.previous{
        left: 15px;
        svg{
            margin-left: 0px;   
            transform: rotateY(0deg);
        }
    }
    &.mini{
        right: 5px;
        svg {
            width: 100%;
            margin-left: 2px;
        }
        &.previous{
            left: 5px;
            padding-left: 3px;
            svg {
                margin-left: 3px;
                margin-right: 2px;
            }
        }
    }
    @media only screen and (min-width: 1024px){
        right: 30px;
        padding-left: 8px;
        &.previous{
            left: 30px;
            padding-left: 8px;
        }
        &.mini{
            width: 35px;
            height: 35px;
        }   
    }
`;

const FloatArrowComponent = ({ onClick, previous, visible, className }) => (
    <FloatControlButton
        previous={previous}
        onClick={onClick}
        noVisible={!visible}
        className={className}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
    </FloatControlButton>
)

export default FloatArrowComponent;
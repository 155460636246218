import styled from '@emotion/styled';
// Colores
import { mediaDevices } from '../../../utilities/mediaDevices.const';
import { 
  kBgGreyColor,
  kLigthGrey,
  kPrimaryColor,
} from '../../../utilities/pallete.const';

export const InputGroupAnimated = styled.div`
  width: 100%;
  position: relative;
  textarea{
    resize: none;
  }
  input, textarea{
    z-index: 0;
    width: 100%;
    height: 100%;
    padding: 18px 20px;
    font-size: 1.4rem;
    border: 2px solid ${kLigthGrey};
    border-radius: 10px;
    background-color: ${kBgGreyColor};
    &:focus{
      outline: none;
      border-color: ${kPrimaryColor};
    }
    &.notEmpty + label,
    &:focus + label{
      top: -10px;
      font-size: 14px;
      color: ${kPrimaryColor};
      padding: 5px 15px 0 15px;
    }
    & + label{
      top: 30%;
      background-color: ${kBgGreyColor};
      position: absolute;
      left: 0px;
      width: auto;
      pointer-events: none;
      z-index: 1;
      left: 20px;
      padding-bottom: 5px;
      transition: all 0.3s ease;
      font-size: 1.2rem;
    }
  }
  textarea + label{
    top: 1.4rem;
  }
  @media ${mediaDevices.tablet}{
    input, textarea{
      font-size: 1rem;
    }
  }
`;
import styled from "@emotion/styled";
import { kAccentColorDark } from "../../utilities/pallete.const";
import { mediaDevices } from "../../utilities/mediaDevices.const";
export const FooterWrapper = styled.footer`
    width: 100%;
    background-color: ${kAccentColorDark};
    padding: 20px;
    text-align: center;
    color: #fff;
    @media ${mediaDevices.tablet}  {
        margin-top: 50px;
    }
`;
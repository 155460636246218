import styled from "@emotion/styled";
// Utilidades
import { kPrimaryColorLight3 } from "../../../utilities/pallete.const";
import { mediaDevices } from "../../../utilities/mediaDevices.const";

export const AboutUsWrapper = styled.section`
    background-color: ${kPrimaryColorLight3};
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    gap: 25px;
    @media ${mediaDevices.tablet} {
        padding: 50px 40px;
    }
    @media ${mediaDevices.laptop} {
        padding: 50px 10vw;
        gap: 50px;
    }
    @media ${mediaDevices.desktop} {
        padding: 50px 20vw;
    }
`;
export const SectionTitle = styled.h2`
    text-align: center;
`;
export const AboutUsMainContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: 25px;
    @media ${mediaDevices.tablet} {
        flex-direction: row;
    }
    @media ${mediaDevices.laptop} {
        gap: 50px;
    }
    @media ${mediaDevices.desktop} {
        gap: 150px;
    }
`
export const AboutUsMainImage = styled.div`
    height: 350px;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    @media ${mediaDevices.laptop} {
        margin: auto 0;
        height: 400px;
    }
`;

export const AboutUsWrapperText = styled.div`
    flex: 1;
    @media ${mediaDevices.tablet} {
        margin: auto 0;
        p{
            margin: 0;
            margin-bottom: 60px;
        }
    }
`;
export const AboutUsWrapperCerts = styled.div`
    margin: 40px 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap: 20px;
    column-gap: 20px;
    @media ${mediaDevices.tablet} {
        margin: 0;
        img{
            height: 50px;
        }
    }
    @media ${mediaDevices.laptopL} {
        grid-template-columns: repeat(4,1fr);
    }
`;
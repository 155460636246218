import styled from "@emotion/styled";
// Colores
import { kBgGreyColor } from "../../utilities/pallete.const";
// Media Query
import { mediaDevices } from "../../utilities/mediaDevices.const";

export const MainWrapper = styled.main`
    width: 100%;
    min-height: 100vh;
    padding-top: 70px;
    background-color: ${props => props.bgColor ?? kBgGreyColor};
    @media ${mediaDevices.tablet} {
        padding-top: 80px;
    }
`;
export const MainContainer = styled.div`
    width: 100%;
    padding: 50px 20px;
    min-height: calc(100vh - 70px);
    margin: 0 auto;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    @media ${mediaDevices.tablet} {
        padding: 50px 40px;
    }
    @media ${mediaDevices.laptop} {
        padding: 50px 50px 80px 50px;
    }
`
import styled from "@emotion/styled";
// MediaQuerys
import { mediaDevices } from "../../utilities/mediaDevices.const";
// Colores
import {
    kPrimaryColor,
    kPrimaryColorDark,
    kDarkGrey,
    kBgGreyColor,
} from "../../utilities/pallete.const";

export const NavWrapper = styled.nav`
    background-color: ${kBgGreyColor};
    height: 70px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    box-shadow: 0px -10px 25px ${kDarkGrey};
    z-index: 20;
    @media ${mediaDevices.tablet}{
        justify-content: start;
        padding: 0 40px;
        height: 100px;
    }
    @media ${mediaDevices.laptop}{
        padding: 0 2.5vw;
    }
    @media ${mediaDevices.laptopL}{
        padding: 0 10vw;
    }
`
export const HamburgerSpacer = styled.div`
    width: 55px;
    @media ${mediaDevices.tablet}{
        display: none;
    }
`;
export const HamburgerMenu = styled.button`
    width: 55px;
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto 0;
    background-color: #fff;
    margin-right: 5px;
    span{
        width: 100%;
        height: 3px;
        background-color: ${kPrimaryColorDark};
        border-radius: 2px;
        transition: all 0.25s ease-out;
    }
    &.is-active{
        span:nth-of-type(1){
            transform: rotate(45deg) translateY(9px) translateX(9px);
        }
        span:nth-of-type(2){
            opacity: 0;
            transform: translateX(-25%);
        }
        span:nth-of-type(3){
            transform: rotate(-45deg) translateY(-9px) translateX(9px);
        }   
    }
    @media ${mediaDevices.tablet}{
        display: none;
    }
`;

export const LogoContainer = styled.a`
    height: 100%;
    display: flex;
    align-items: center;
    & > img{
        height: 80%;
    }
`;

export const MenuContainer = styled.ul`
    background-color: #fff;
    position: fixed;
    width: 100%;
    height: auto;
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    gap: 5px;
    transition: all 0.25s ease-out;
    transform: translateY(-150%);
    z-index: -1;
    box-shadow: 0px 15px 15px -5px ${kDarkGrey};
    &.is-visible{
        transform: translateY(0);
    }
    @media ${mediaDevices.tablet}{
        box-shadow: none;
        position: unset;
        flex-direction: row;
        margin-top: 0;
        padding: 0;
        margin-left: 50px;
        gap: 15px;
        justify-content: space-around;
        align-items: center;
        transform: translateY(0);
        background-color: transparent;
    }
    @media ${mediaDevices.laptopL}{
        gap: 30px;
    }
`;

export const MenuItem = styled.li`
    width: 100%;
    position: relative;
    height: 100%;
    vertical-align: center;
    background-color: #fff;
    a,
    button{
        width: 100%;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 1.5rem;
        cursor: pointer;
    }
    @media ${mediaDevices.tablet}{
        a,
        button{
            padding: 0 5px;
            font-size: 1.5rem;
            text-align: center;
        }
    }
    &:hover {
        ul{
            width: 200px;
            display: block;
        }
    }
`;

export const SubMenuItem = styled.ul`
    position: absolute;
    background-color: #fff;
    padding: 10px;
    font-size: 1.2em;
    display: none;
    width: 100%;
    text-align: center;
    z-index: 2;
    @media ${mediaDevices.tablet}{
        text-align: left;
    }
    li{
        background-color: #fff;
        width: auto;
        cursor: pointer;
        padding: 8px 15px;
        &:hover{
            background-color: #8E76E5;
        }
    }
`;
export const ButtonAction = styled.button`
    background-color: ${kPrimaryColor};
    color: ${kBgGreyColor};
    margin: 0 auto;
    padding: 15px 40px;
    font-size: 1.5rem;
    transition: all 0.25s ease-in-out;
    border-radius: 10px;
    cursor: pointer;
    display: block;
    &:hover{
        transform: scale(1.1);
        box-shadow: 0px 2px 20px rgba(41, 21, 112, 0.75);
    }
    @media ${mediaDevices.tablet}{
        width: 100%;
        padding: 15px 20px;
    }
`;
import { useState } from 'react';
import { animateScroll as scroll } from "react-scroll";
import ModalServices from '../modalServices/modalServices.component';
// Estilos
import {
    NavWrapper,
    LogoContainer,
    HamburgerSpacer,
    HamburgerMenu,
    MenuContainer,
    MenuItem,
    SubMenuItem,
    ButtonAction,
} from './topNavMenu.styled';
// Images
import Logo from '../../images/smlogo_p.png';

const TopNavMenu = () => {
    const ListServices = [
        {
            main: 'Commercial Services',
            childItems: [
                'Office',
                'Industrial',
                'Lab & Medical Moving',
                'IT Relocation',
                'Office & Building Decommission',
                'Warehousing & Storage',
            ]
        },
        {
            main: 'Installation',
            childItems: [
                'Hotel & Restaurant FF&E',
                'Student Housing Installation Experts',
                'Healthcare & Data Center Expansion',
                'Retail Relocation & Fixtures',
                'Modular & Cubical Furniture Systems',
                'Professional Artwork',
            ]
        },
        {
            main: 'Logistics',
            childItems: []
        },
        {
            main: 'Final Mile / White Glove',
            childItems: []
        },
    ]

    const [menuIsActive, setMenuIsActive] = useState(false);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [service, setService] = useState('')

    const onOpenServiceModal = (service) => {
        setModalIsVisible(true);
        setService(service);
    }

    return (
        <>
            <NavWrapper>
                <HamburgerSpacer />
                <a href="/">
                    <LogoContainer>
                        <img src={Logo} alt="Logotipo" />
                    </LogoContainer>
                </a>
                <HamburgerMenu
                    className={menuIsActive && "is-active"}
                    onClick={() => { setMenuIsActive(!menuIsActive) }}
                >
                    <span />
                    <span />
                    <span />
                </HamburgerMenu>
                <MenuContainer className={menuIsActive && "is-visible"}>
                    {
                        ListServices.map(({ main, childItems }, index) => (
                            <MenuItem key={index}>
                                {
                                    childItems.length > 0
                                        ? <>
                                            <button>{main}</button>
                                            <SubMenuItem>
                                                {
                                                    childItems.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => { onOpenServiceModal(item) }}
                                                        >
                                                            {item}
                                                        </li>
                                                    ))
                                                }
                                            </SubMenuItem>
                                        </>
                                        : <button onClick={() => { onOpenServiceModal(main) }}>
                                            {main}
                                        </button>
                                }
                            </MenuItem>
                        ))
                    }
                    <ButtonAction
                        onClick={() => {
                            scroll.scrollToBottom(500)
                        }}
                    >
                        Contact us!
                    </ButtonAction>
                </MenuContainer>
            </NavWrapper>
            <ModalServices
                isVisible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                service={service}
            />
        </>
    )
}

export default TopNavMenu;
const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptopS: '900px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1920px',
};
export const mediaDevices = {
    mobileS: `only screen and (min-width: ${sizes.mobileS})`,
    mobileM: `only screen and (min-width: ${sizes.mobileM})`,
    mobileL: `only screen and (min-width: ${sizes.mobileL})`,
    tablet: `only screen and (min-width: ${sizes.tablet})`,
    laptopS: `only screen and (min-width: ${sizes.laptop})`,
    laptop: `only screen and (min-width: ${sizes.laptop})`,
    laptopL: `only screen and (min-width: ${sizes.laptopL})`,
    desktop: `only screen and (min-width: ${sizes.desktop})`,
};
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
// Estilos
import {
    ModalWrapper,
    ModalContainer,
    ContentWrapper,
    ButtonWrapper,
    ButtonClose,
} from './modalWindow.styled'

const ModalWindow = ({ children, className, onClickClose }) => {

    let portalNode = document.createElement('div');

    useEffect(() => {
        document.body.appendChild(portalNode);
        return () => {
            portalNode.remove();
        }
    }, [portalNode]);

    const handleClickOutside = e => {
        if (portalNode.isEqualNode(e.target.parentNode)) {
            onClickClose();
        }
    }
    return ReactDOM.createPortal(
        <ModalWrapper
            onClick={handleClickOutside}
            className={className}
        >
            <ModalContainer>
                <ButtonWrapper>
                    <ButtonClose onClick={() => { onClickClose() }}>
                        <span />
                        <span />
                    </ButtonClose>
                </ButtonWrapper>
                <ContentWrapper>
                    {children}
                </ContentWrapper>
            </ModalContainer>
        </ModalWrapper >,
        portalNode
    )
}

// Documentacion con PropTypes
ModalWindow.propTypes = {
    className: PropTypes.string,
    onClickOut: PropTypes.func,
}
ModalWindow.defaultProps = {
    onClickOut: () => { },
}

export default ModalWindow
import styled from '@emotion/styled';
// Componentes Generales
import FullScreenContainer from '../../common/fullScreenContainer.styled';
// Colores
import {
    kBgGreyColor,
    kPrimaryColorLight0,
} from '../../../utilities/pallete.const'
import { mediaDevices } from "../../../utilities/mediaDevices.const";


// Styled component del Carousel
export const HeroCarouselWrapper = styled(FullScreenContainer)`
    position: relative;
    height: calc(100vh);
    min-height: 315px;
    @media ${mediaDevices.tablet}{
        height: 60vh;
        min-height: 380px;
        max-height: 800px;
    }
    @media ${mediaDevices.laptop}{
        height: 80vh;
        min-height: 490px;
    }
    @media ${mediaDevices.laptopL}{
        height: 85vh;
    }
    background-image: url(${props => props.background});
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    overflow-x: hidden;
`;
export const HeroSlidesWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    overflow: hidden;
`;
export const HeroSlideContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
`;
export const HeroSlideContainerInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 60px;
    gap: 20px;
    @media ${mediaDevices.tablet}{
        width: 70vw;
    }
    @media ${mediaDevices.laptop}{
        gap: 0;
    }
    @media ${mediaDevices.laptopL}{
        width: 55vw;
    }
`;
export const HeroSlideContainerInfoContainerTitle = styled.button`
    font-family: var(--fontTitle);
    text-align: left;
    font-weight: normal;
    font-size: 26px;
    width: 100%;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    background: transparent;
    &:hover{
        transform: scale(1.02);
    }
    @media ${mediaDevices.tablet}{
        font-size: 4.5rem;
    }
    @media ${mediaDevices.laptop}{
        font-size: 5rem;
        margin-bottom: 10px;
    }
    @media ${mediaDevices.laptopL}{
        font-size: 5.5rem;
        margin-bottom: 50px;
    }
    color: ${kBgGreyColor};
    text-align: center!important;
    font-weight: 600;
`;
export const HeroSlideContainerInfoContainerText = styled.p`
    color: ${kBgGreyColor};
    margin-top: 20px;
    font-size: 1.4rem;
    font-family: var(--fontText);
    @media ${mediaDevices.tablet}{
        margin: 0;
    }
    @media ${mediaDevices.laptop}{
        font-size: 1.6rem;
    }
`;
export const HeroSlideContainerBookContainer = styled.button`
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 45px 70px;
    width: 50vw;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    &:hover{
        transform: scale(1.02);
    }
    @media ${mediaDevices.tablet}{
        display: flex;
    }
    @media ${mediaDevices.laptop}{
        padding: 60px 100px;
    }
`;
export const HeroSlideMainImage = styled.img`
    width: auto!important;
    height: 100%;
    max-height: 250px;
    @media ${mediaDevices.tablet}{
        max-height: 400px;
    }
    @media ${mediaDevices.laptop}{
        max-height: 450px;
    }
    @media ${mediaDevices.laptopL}{
        max-height: 600px;
    }
`;
export const HeroSlideContainerFestivalImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
`;
/* Botones de navegacion */
export const HeroNavigationButton = styled.button`
    width: 20px;
    height: 20px;
    margin: 0 5px;
    border-radius: 100%;
    border: 2px solid transparent;
    position: relative;
    z-index: 3;
    cursor: pointer;
    &::before {
        position: absolute;
        content: '';
        width: 50%;
        height: 50%;
        background-color: ${kBgGreyColor};
        left: 25%;
        bottom: 25%;
        border-radius: 100%;
    }
    @media ${mediaDevices.laptop}{
        width: 25px;
        height: 25px;
    }
    @media ${mediaDevices.laptopL}{
        width: 30px;
        height: 30px;
    }
    ${props => (props.active) ?
        `border-color: ${kPrimaryColorLight0};
        &::before{
            background-color: ${kPrimaryColorLight0};
        }
        ` : ''
    };
`;
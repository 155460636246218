import styled from "@emotion/styled";
// Utilidades
import { mediaDevices } from "../../../utilities/mediaDevices.const";
import {
    kBgGreyColor,
    kPrimaryColor,
} from '../../../utilities/pallete.const'

export const MapWrapper = styled.section`
    padding-top: 10px;
`;

export const MapTitle = styled.h2`
    text-align: center;
    margin-bottom: 40px;
`;
export const MapContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    @media ${mediaDevices.tablet} {
        position: relative;
    }
`;
export const DataContainer = styled.div`
    z-index: 2;
    background: #fff;
    padding: 20px;
    position: unset;
    margin-bottom: 20px;
    @media ${mediaDevices.tablet} {
        margin: 10px;
        box-shadow: 0px 0px 20px rgb(0 0 0 / 30%);
        position: absolute;
        top: 0;
        left: 0;
    }
`;
export const DataTitle = styled.h3`
    font-size: 2rem;
`;
export const InfoList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 30px;
`;
export const InfoItem = styled.li`
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
    gap: 20px;
    p{
        margin: 0;
        font-size: 1.2rem;
    }
`;
export const CallToAction = styled.button`
    background-color: ${kPrimaryColor};
    color: ${kBgGreyColor};
    width: auto;
    padding: 15px 30px;
    font-size: 1.2rem;
    transition: all 0.25s ease-in-out;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 30px;
    &:hover{
        transform: scale(1.05);
        box-shadow: 0px 2px 10px rgba(41, 21, 112, 0.75);
    }
`;
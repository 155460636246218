import styled from '@emotion/styled';
// Utilidades
import { kPrimaryColorDark } from '../../../utilities/pallete.const'

export const ModalWrapper = styled.div`
    @keyframes fadein {
        from {opacity:0;}
        to {opacity:1;}
    }
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
    justify-content: center;
    flex-direction: column;
    padding: 0 25px;
    &.visible{
        display: flex;
        animation: fadein 0.15s ease-out;
    }
    @media only screen and (min-width: 500px){
        padding: 0 40px;
    }
    @media only screen and (min-width: 768px){
        padding: 0 20vw;
        &.lg{
            padding: 0 15vw;
        }
    }
    @media only screen and (min-width: 1024px){
        padding: 0 30vw;
        &.lg{
            padding: 0 20vw;
        }
    }
    @media only screen and (min-width: 1920px){
        padding: 0 35vw;
        &.lg{
            padding: 0 30vw;
        }
    }
`;
export const ModalContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 0;
`;
export const ContentWrapper = styled.div`
    padding: 0 20px 30px 20px;
    @media only screen and (min-width: 1024px){
        padding: 0 30px 40px 30px;
    }
`;
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;
    padding-top: 15px;
    padding-right: 5px;
`;
export const ButtonClose = styled.button`
    width: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto 0;
    background-color: #fff;
    cursor: pointer;
    span{
        width: 100%;
        height: 2px;
        background-color: ${kPrimaryColorDark};
        border-radius: 2px;
    }
    span:nth-of-type(1){
        transform: rotate(45deg) translateY(7px) translateX(6px);
    }
    span:nth-of-type(2){
        transform: rotate(-45deg) translateY(-9px) translateX(8px);
    } 
`;
// Estilos
import {
    AboutUsWrapper,
    SectionTitle,
    AboutUsMainContainer,
    AboutUsMainImage,
    AboutUsWrapperText,
    AboutUsWrapperCerts,
} from "./aboutUs.styled";
// Images
import Ac1 from '../../../images/ac-1.png';
import Ac4 from '../../../images/ac-4.jpg';
import Warehouse from '../../../images/warehouse.jpg';

const AboutUs = () => {
    return (
        <AboutUsWrapper>
            <SectionTitle>Who we are</SectionTitle>
            <AboutUsMainContainer>
                <AboutUsMainImage>
                    <img src={Warehouse} alt="¿Quienes somos?" />
                </AboutUsMainImage>
                <AboutUsWrapperText>
                    <p>
                        At <b>Superior Relocations Services,</b> we are committed to our customers’ complete satisfaction while offering the best possible value.   We provide all types of commercial moving and customized services to meet our customer’s specialized needs.  With <b>over 130+ collective years of management experience in the relocation industry,</b> you can count on our friendly staff to answer any transportation questions and our dependable, uniformed movers to carefully handle and install your goods safely.
                    </p>
                    <AboutUsWrapperCerts>
                        <img src={Ac1} alt="Certificate" />
                        <img src={Ac4} alt="Certificate" />
                    </AboutUsWrapperCerts>
                </AboutUsWrapperText>
            </AboutUsMainContainer>
        </AboutUsWrapper>
    )
}

export default AboutUs;
import styled from "@emotion/styled";
// Utilidades
import { mediaDevices } from "../../utilities/mediaDevices.const";
import {
    kBgGreyColor,
    kPrimaryColor,
} from '../../utilities/pallete.const'

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const InfoTitle = styled.h3`
    font-size: 1.8rem;
`;
export const InfoParagraph = styled.p`
    font-size: 1.4rem;
    ul{
        margin-top: 20px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        gap: 1px;
    }
    li{
        list-style: circle;
    }
`;

export const CallToAction = styled.button`
    background-color: ${kPrimaryColor};
    color: ${kBgGreyColor};
    width: auto;
    margin: 15px auto 0 auto;
    padding: 15px 40px;
    font-size: 1.5rem;
    transition: all 0.25s ease-in-out;
    border-radius: 10px;
    cursor: pointer;
    display: block;
    &:hover{
        transform: scale(1.1);
        box-shadow: 0px 2px 20px rgba(41, 21, 112, 0.75);
    }
    @media ${mediaDevices.tablet} {
        margin: 0;
        margin-top: 25px;
        margin-left: auto;
    }
`;


import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import customStyle from './style.module.scss';

import withAutoplay from 'react-awesome-slider/dist/autoplay';

import useInitialPageHeight from '../../../hooks/useInitialPageHeight';
import { GalleryWrapper } from "./gallery.styled"
// Images
import Gal1 from '../../../images/gal-1.JPG';
import Gal2 from '../../../images/gal-2.JPG';
import Gal3 from '../../../images/gal-3.JPG';
import Gal5 from '../../../images/gal-5.JPG';
import Gal6 from '../../../images/gal-6.JPG';
import Gal7 from '../../../images/gal-7.JPG';
import Gal10 from '../../../images/gal-10.JPG';
import Gal11 from '../../../images/gal-11.JPG';
import Gal12 from '../../../images/gal-12.JPG';
import Gal13 from '../../../images/gal-13.JPG';
import Gal15 from '../../../images/gal-15.JPG';
import Gal18 from '../../../images/gal-18.JPG';
import Gal19 from '../../../images/gal-19.JPG';
import Gal20 from '../../../images/gal-20.JPG';
import Gal21 from '../../../images/gal-21.JPG';
import Gal22 from '../../../images/gal-22.JPG';
import Gal23 from '../../../images/gal-23.JPG';
import Gal24 from '../../../images/gal-24.JPG';
import Gal25 from '../../../images/gal-25.JPG';
import Gal30 from '../../../images/gal-30.JPG';
import Gal31 from '../../../images/gal-31.JPG';
import Gal32 from '../../../images/gal-32.jpg';
import Gal33 from '../../../images/gal-33.jpg';
import Gal34 from '../../../images/gal-34.jpg';
import Gal35 from '../../../images/gal-35.jpg';

import Vid1 from '../../../videos/vid1.MOV'

const VideoPlayer = ({ source }) => {
    const height = useInitialPageHeight();
    return (
        <video height={height} autoPlay={true} controls={false} muted={true}>
            <source src={source} type="video/mp4" />
            Tu navegador no soporta el tag de video.
        </video>
    );
};

const Gallery = () => {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    return (
        <GalleryWrapper>
            <AutoplaySlider
                play={true}
                interval={50000}
                cssModule={[customStyle]}
                animation="cubeAnimation"
                fillParent={true}
            >
                <div>
                    <VideoPlayer source={Vid1} />
                </div>
                <div data-src={Gal18} />
                <div data-src={Gal19} />
                <div data-src={Gal20} />
                <div data-src={Gal1} />
                <div data-src={Gal2} />
                <div data-src={Gal3} />
                <div data-src={Gal5} />
                <div data-src={Gal6} />
                <div data-src={Gal7} />
                <div data-src={Gal10} />
                <div data-src={Gal11} />
                <div data-src={Gal12} />
                <div data-src={Gal12} />
                <div data-src={Gal13} />
                <div data-src={Gal15} />
                <div data-src={Gal21} />
                <div data-src={Gal22} />
                <div data-src={Gal23} />
                <div data-src={Gal24} />
                <div data-src={Gal25} />
                <div data-src={Gal30} />
                <div data-src={Gal31} />
                <div data-src={Gal32} />
                <div data-src={Gal33} />
                <div data-src={Gal34} />
                <div data-src={Gal35} />
            </AutoplaySlider>
        </GalleryWrapper>
    )
}

export default Gallery;
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
// Utilidades
import { mediaDevices } from "../../../utilities/mediaDevices.const";
import {
    kAccentColor,
    kBgGreyColor,
    kPrimaryColor,
} from '../../../utilities/pallete.const'

export const BeltWrapper = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${kAccentColor};
    padding: 50px 20px;
    color: ${kBgGreyColor};
    justify-content: space-between;
    gap: 40px;
    @media ${mediaDevices.tablet} {
        padding: 50px 40px;
        gap: 50px;
        flex-direction: row;
    }
    @media ${mediaDevices.laptop} {
        padding: 50px 10vw;
        gap: 80px;
    }
    @media ${mediaDevices.desktop} {
        padding: 50px 20vw;
    }
`;
const animateButton = keyframes`
    0%{
        transform: scale(1);
    } 
    50% {
        transform: scale(1.1);
        box-shadow: 0px 2px 20px rgba(41, 21, 112, 0.75);
    }
    100%{
        transform: scale(1);
    } 
`;

export const CallToAction = styled.button`
    background-color: ${kPrimaryColor};
    color: ${kBgGreyColor};
    width: auto;
    margin: 0 auto;
    padding: 15px 40px;
    font-size: 1.5rem;
    transition: all 0.25s ease-in-out;
    border-radius: 10px;
    cursor: pointer;
    animation: ${animateButton} infinite 3s 1s;
    &:hover{
        transform: scale(1.1);
        box-shadow: 0px 2px 20px rgba(41, 21, 112, 0.75);
    }
    @media ${mediaDevices.tablet} {
        margin: 0;
    }
`;
// Estilos
import { 
    FooterWrapper
} from "./footer.styled";

const Footer = () =>{
    const date = new Date();
    return (
        <FooterWrapper>
            Copyright {date.getFullYear()} | Superior Move - Relocation Services
        </FooterWrapper>
    )
}

export default Footer;
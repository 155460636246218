import { useEffect, useState } from 'react';

const useInitialPageHeight = () => {
    const [height, setHeight] = useState('calc(90vh - 75px)');

    useEffect(() => {
        const updateHeight = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 1200) {
                setHeight('750px');
            } else if (screenWidth >= 1024) {
                setHeight('700px');
            } else if (screenWidth >= 768) {
                setHeight('600px');
            } else {
                const screenHeight = window.innerHeight;
                const calculatedHeight = screenHeight - 75 + 'px';
                setHeight(calculatedHeight);
            }
        };

        // Llama a la función al cargar la página
        updateHeight();

        // Agrega un listener para cambiar la altura cuando el tamaño de la ventana cambia
        window.addEventListener('resize', updateHeight);

        // Limpia el listener al desmontar el componente
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []); // El segundo parámetro es un array vacío para ejecutar el efecto solo una vez al montar el componente

    return height;
};

export default useInitialPageHeight;

import { animateScroll as scroll } from "react-scroll";
// Estilos
import { BeltWrapper, CallToAction } from './callToActionBelt.styled'

const callToActionBelt = () => {

    return (
        <BeltWrapper>
            <h2>Do you have questions?</h2>
            <CallToAction
                onClick={() => { scroll.scrollToBottom(500) }}
            >
                Contact us!
            </CallToAction>
        </BeltWrapper>
    )
}

export default callToActionBelt;
import {
    WhyUsWrapper,
    TitleSection,
    CardsContainer,
    ImageContainer,
    CardItem,
    CardTitle,
    DesContainer,
} from "./whyUs.styled";
// Recursos
// import Img1 from '../../../svg/whyUs1.svg';
import Img2 from '../../../svg/whyUs2.svg';
import Img3 from '../../../svg/whyUs3.svg';
import Img4 from '../../../svg/whyUs4.svg';

const WhyUs = () => {
    // Function to calculate years
    const getBussinesYears = ()=> {
        // Current date
        const currentDate = new Date();
        // Reference year (2006)
        const referenceYear = 2006;
        // Calculate the difference in years
        return currentDate.getFullYear() - referenceYear;
    }
    return (
        <WhyUsWrapper>
            <TitleSection>¿Why Us?</TitleSection>
            <CardsContainer>
                <CardItem>
                    <CardTitle>Professional Service</CardTitle>
                    <ImageContainer>
                        <img src={Img4} alt="Why Us 1" />
                    </ImageContainer>
                    <p>
                        At Superior Relocation Services we are trained to offer you the best professional moving services
                        for your relocation dollar
                    </p>
                </CardItem>
                <CardItem>
                    <CardTitle>Years of Exprience</CardTitle>
                    <ImageContainer>
                        <img src={Img3} alt="Why Us 3" />
                    </ImageContainer>
                    <DesContainer>
                        <div>
                            <h4>{getBussinesYears()}+</h4>
                            <p>Years in business</p>
                        </div>
                        <div>
                            <h4>130+</h4>
                            <p>Combined team years of experience</p>
                        </div>
                    </DesContainer>
                </CardItem>
                <CardItem>
                    <CardTitle>One Time<br />Delivery</CardTitle>
                    <ImageContainer>
                        <img src={Img2} alt="Why Us 2" />
                    </ImageContainer>
                    <p>
                        At Superior Relocation Services we are committed to fast and efficient pick up and delivery for our
                        customers. Whether pick up of an entire truckload or white glove internal delivery of one item to
                        your biggest client, Superior always offers the best quality service every time.</p>
                </CardItem>
            </CardsContainer>
        </WhyUsWrapper>
    );
}

export default WhyUs;
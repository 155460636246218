import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
// Componentes
import InputAnimated from '../common/inputAnimated/inputAnimated.component'
// Utilidades
import { isEmailValid } from '../../utilities/funcs';
// Estilos
import {
    ContactUsWrapper,
    SectionTitle,
    FormContainer,
    InputsWrapper,
    InfoMessage,
    SendButtonContainer,
    ActionButton,
} from "./contactUs.styled";
import { kDarkGrey } from '../../utilities/pallete.const'

const ContactUs = () => {
    // Datos de formulario
    const [data, setData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });

    const [canSendData, setCanSendData] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [alertData, setAlertData] = useState({
        hasError: false,
        message: "",
        visible: false,
    })

    // Funcion para validar informacion a enviar
    useEffect(() => {
        setCanSendData(
            data.name !== "" &&
            data.email !== "" &&
            data.message !== "" &&
            isEmailValid(data.email)
        )
    }, [data.name, data.email, data.message]);

    // Enviar informacion de registro
    const onSubmitForm = async () => {
        const urlContactForm = 'https://script.google.com/macros/s/AKfycbxoWrrDz1d5aSzAZmxy2hwtnq5h9M0wBEViFA0122fOofobM7qKvEh4Pcs6KpsXOkezZw/exec'
        setIsFormLoading(true);
        // Preparar informacion para enviar
        const sendData = new FormData();
        sendData.append('Email', data.email);
        sendData.append('Name', data.name);
        sendData.append('Subject', data.subject);
        sendData.append('Message', data.message);
        sendData.append('Date', new Date().toLocaleString());
        try {
            const req = await fetch(urlContactForm, {
                method: 'POST',
                body: sendData
            }).then(res => (res.json()));
            if (req.result === 'success') {
                setIsFormLoading(false);
                // Limpiar datos
                setData({
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                })
                // Enviar alerta de exito
                setAlertData({
                    hasError: false,
                    visible: true,
                    message: "We've recived your mesage 🙂,\n we'll be in touch soon!"
                });
                await new Promise(res => (setTimeout(res, 5000)));
                setAlertData({
                    hasError: false,
                    visible: false,
                    message: "",
                })
            }
        } catch (e) {
            console.log(e);
            setIsFormLoading(false);
            setAlertData({
                hasError: true,
                visible: true,
                message: "Something was wrong 😣, try it later"
            });
        }
    }

    return (
        <ContactUsWrapper>
            <SectionTitle>Contact us</SectionTitle>
            <FormContainer>
                <InputsWrapper>
                    <InputAnimated
                        type="text"
                        id="in-name"
                        label="Your name*"
                        value={data.name}
                        setValue={newVal => {
                            setData({ ...data, name: newVal })
                        }}
                    />
                    <InputAnimated
                        type="email"
                        id="in-email"
                        label="Your email*"
                        value={data.email}
                        setValue={newVal => {
                            setData({ ...data, email: newVal })
                        }}
                    />
                    <InputAnimated
                        type="text"
                        id="in-subject"
                        label="Subject"
                        value={data.subject}
                        setValue={newVal => {
                            setData({ ...data, subject: newVal })
                        }}
                    />
                </InputsWrapper>
                <InputsWrapper>
                    <InputAnimated
                        isTextArea={true}
                        rows="10"
                        id="in-message"
                        label="Message"
                        value={data.message}
                        setValue={newVal => {
                            setData({ ...data, message: newVal })
                        }}
                    />
                </InputsWrapper>
            </FormContainer>
            {/* Mensaje de Alerta */}
            <InfoMessage className={alertData.visible ? 'visible' : ''} hasError={alertData.hasError}>
                {alertData.message}
            </InfoMessage>
            {/* Boton para enviar formulario */}
            <SendButtonContainer>
                <ActionButton
                    className={(!canSendData || isFormLoading) ? 'isPressed' : ''}
                    disabled={(!canSendData || isFormLoading)}
                    onClick={onSubmitForm}
                >
                    {
                        isFormLoading
                            ? <TailSpin color={kDarkGrey} height={25} width={25} />
                            : 'Send'
                    }
                </ActionButton>
            </SendButtonContainer>
        </ContactUsWrapper>
    )
}

export default ContactUs;
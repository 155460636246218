import './styles/normalize.css';
import './styles/globals.css';
import './styles/hero.custom.css'

// Componentes
import ContactUs from './components/contactUs/contactUs.component'
import Footer from './components/footer/footer.component'
import { TopNavMenu } from './components';
// Estilos
import { MainWrapper } from './components/mainLayout/mainLayout.styled';

import {
  Hero,
  AboutUs,
  WhyUs,
  CallToActionBelt,
  Gallery,
  CustomersFeedBack,
  GoogleMap
} from './components/index/'

function App() {
  return (
    <>
      <TopNavMenu />
      <MainWrapper>
        <Hero
          listItems={[
            {
              title: "Office Moving",
              description: "With our turn key solutions, we are committed to take your project from start to finish by providing knowledge and innovation developed through 40+ years of experience. From a single desk to a multiple story office complex, our goal is to provide cost effective, quality work in a timeline that exceeds your relocation needs.",
              srcPicture: "/images/hero-1.jpg",
            },
            {
              title: "Commercial Moving",
              description: "Our Commercial Moving Process combines an experienced, professional team with the right moving equipment to make every move a successful one. We will conduct an extensive site survey to accurately determine the cost, time and labor required for the project.",
              srcPicture: "/images/hero-2.jpg",
            },
          ]}
        />
        <AboutUs />
        <WhyUs />
        <CallToActionBelt />
        <Gallery />
        <CustomersFeedBack />
        <GoogleMap />
        <ContactUs />
      </MainWrapper>
      <Footer />
    </>
  );
}

export default App;

import { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import GoogleMaps from "simple-react-google-maps";
// Estilos
import {
    MapWrapper,
    MapTitle,
    MapContainer,
    DataContainer,
    DataTitle,
    InfoList,
    InfoItem,
    CallToAction,
} from "./googleMap.styled";

const GoogleMap = () => {
    const [mapHeight, setMapHeight] = useState('400px');

    useEffect(() => {
        // Manejo de cambio de tamaño
        const handleResize = () => {
            let height = 400;
            const windowWidth = window.innerWidth;
            if (windowWidth < 768) {
                height = 400;
            } else if (windowWidth >= 768 && windowWidth < 1024) {
                height = 500;
            } else if (windowWidth >= 1024 && windowWidth < 1920) {
                height = 550;
            } else {   // > 1920
                height = 600;
            }
            setMapHeight(`${height}px`);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    // Libreria usada solo en lado del cliente
    return (
        <MapWrapper>
            <MapTitle>
                <h2>Located</h2>
            </MapTitle>
            <MapContainer>
                <DataContainer>
                    <DataTitle>Located in Grand Prairie, Texas</DataTitle>
                    <h4>
                        Servicing the entire state of Texas and central United States.
                    </h4>
                    <InfoList>
                        <InfoItem>
                            <b>Main Number</b>
                            <p>817-753-6701</p>
                        </InfoItem>
                        <InfoItem>
                            <b>Toll Free</b>
                            <p>888-591-MOVE (6683)</p>
                        </InfoItem>
                        <InfoItem>
                            <b>Email</b>
                            <p>info@superior-move.com</p>
                        </InfoItem>
                    </InfoList>
                    <CallToAction
                        onClick={() => { scroll.scrollToBottom(500) }}
                    >
                        Contact us!
                    </CallToAction>
                </DataContainer>
                <GoogleMaps
                    apiKey={"AIzaSyDRX6hhA_CBVl7bf3b96hD2RsYVfb65lBw"}
                    style={{ height: mapHeight, width: "100%" }}
                    zoom={15}
                    center={{ lat: 32.764098, lng: -97.044186 }}
                    markers={{ lat: 32.764098, lng: -97.044186 }}
                />
            </MapContainer>
        </MapWrapper>
    );
}

export default GoogleMap;
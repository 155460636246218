import PropTypes from 'prop-types'
// Carousel
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Styled Components
import {
	HeroCarouselWrapper,
	HeroSlidesWrapper,
	HeroSlideContainer,
	HeroSlideContainerInfoContainer,
	HeroSlideContainerInfoContainerTitle,
	HeroSlideContainerInfoContainerText,
	HeroNavigationButton
} from './hero.styled'
// Componentes Generados
import FloatArrowComponent from '../floatArrow.component';
// Imagenes
import Back from '../../../images/gal-3.JPG'

const HeroCarousel = () => {
	const listItems = [
		{
			title: "Commercial Services",
			description: "Superior’s commercial moving process combines our highly experienced, professional team with all the right moving equipment to ensure your relocation is a successful one.  We will conduct an extensive pre-move site survey to accurately determine the time and labor needed for the project, while providing a competitive, customized quote.",
		},
		{
			title: "Installation",
			description: "Over the many years, we have been afforded the opportunity to assist with all types of installation projects, large and small.  We can provide turnkey and customized installation services throughout the country with our well trained, knowledgeable crews that are eager to assist with any needs that arise.",
		},
		{
			title: "Logistics",
			description: "With our turnkey solutions, we are committed to coordinate your supply chain needs from start to finish by providing knowledge and innovation developed through our management team’s collective 130+ years of experience.  The first step in making a distribution or logistics project a success is to gain the full understanding of your needs and plan accordingly.",
		},
		{
			title: "Final Mile / White Glove",
			description: "At Superior,  we successfully provide final mile, white glove services in our daily operations by prioritizing fast, accurate delivery while meeting the high expectations of your customer.  Let the last leg of your item’s journey be the best!",
		},
	]
	// Componente principal de todo el Carousel
	return (
		<HeroCarouselWrapper background={Back}>
			<HeroSlidesWrapper>
				<Carousel
					showThumbs={false}
					dynamicHeight={false}
					autoPlay={true}
					infiniteLoop={true}
					showStatus={false}
					swipeable={false}
					interval={7500}
					renderArrowPrev={(clickHandler) => (
						<FloatArrowComponent
							className="previous"
							onClick={clickHandler}
							visible={true}
						/>
					)}
					renderArrowNext={(clickHandler) => (
						<FloatArrowComponent
							onClick={clickHandler}
							visible={true}
						/>
					)}
					renderIndicator={(clickHandler, isSelected) => (
						<HeroNavigationButton
							active={isSelected}
							onClick={clickHandler}
						/>
					)}
				>
					{
						listItems.map((element, index) => (
							<HeroSlideContainer key={index}>
								<HeroSlideContainerInfoContainer>
									<HeroSlideContainerInfoContainerTitle
										onClick={() => { }}>
										{element.title}
									</HeroSlideContainerInfoContainerTitle>
									<HeroSlideContainerInfoContainerText>
										{element.description.split("\n").map((text, index) => (
											<span key={index}>
												{text}
												<br />
											</span>
										))}
									</HeroSlideContainerInfoContainerText>
								</HeroSlideContainerInfoContainer>
							</HeroSlideContainer>
						))
					}
				</Carousel>
			</HeroSlidesWrapper>
		</HeroCarouselWrapper >
	);
}

// Documentacion con proptypes
HeroCarousel.propTypes = {
	listItems: PropTypes.array.isRequired
}

export default HeroCarousel;
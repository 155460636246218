import styled from "@emotion/styled";
import { mediaDevices } from '../../utilities/mediaDevices.const';
import {
  kBgGreyColor,
  kPrimaryColor,
  kPrimaryColorLight0,
  kDefeatColor,
  kSuccessColor
} from '../../utilities/pallete.const';

export const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  gap: 0;
  @media ${mediaDevices.tablet} {
    padding: 50px 40px;
    margin-top: 25px;
  }
  @media ${mediaDevices.laptop} {
    padding: 50px 10vw;
  }
  @media ${mediaDevices.desktop} {
    padding: 50px 20vw;
  }
`;
export const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 50px;
`;
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  @media ${mediaDevices.tablet}{
    flex-direction: row;
    gap: 40px;
    max-height: 280px;
    margin: 0;
  }
`;
export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  flex: 1;
`;
export const SendButtonContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`;
export const InfoMessage = styled.p`
  text-align: center;
  margin-top: 0;
  font-size: 1.2rem;
  color: ${props => props.hasError ? kDefeatColor : kSuccessColor};
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.25s ease-in-out;
  &.visible{
    opacity: 1;
    transform: translateY(0);
  }
`;
export const ActionButton = styled.button`
    background-color: ${kPrimaryColor};
    color: ${kBgGreyColor};
    width: auto;
    margin: 0 auto;
    padding: 15px 40px;
    font-size: 1.5rem;
    transition: all 0.25s ease-in-out;
    border-radius: 10px;
    cursor: pointer;
    display: block;
    &:hover{
        transform: scale(1.1);
        box-shadow: 0px 2px 20px rgba(41, 21, 112, 0.75);
    }
    &.isPressed{
      background-color: ${kPrimaryColorLight0};
      &:hover{
        transform: scale(1);
        box-shadow: none;
      }
    }
    @media ${mediaDevices.tablet} {
        margin: 0 0 0 auto ;
    }
`;
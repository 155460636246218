import { animateScroll as scroll } from "react-scroll";
// Componente
import ModalWindow from "../common/modalWindow/modalWindow.component"
// Estilos
import {
    InfoWrapper,
    InfoTitle,
    InfoParagraph,
    CallToAction,
} from "./modalServices.styled"

const ModalServices = ({ isVisible, setIsVisible, service }) => {
    const DictServicesInfo = {
        /* Category - Commercial Services  */
        'Office': {
            desc:
                <>
                    From a small office to a multi-story complex, we can provide cost effective moving services within a timeline that exceeds your relocation needs.
                    <br /><br />
                    And if you need assistance moving or reconfiguring your modular or cubicle furniture, our technicians are some of the best in the business.
                </>
        },
        'Industrial': {
            desc:
                <>
                    Whether moving a warehouse, manufacturing line, cell phone towers or industrial business, our team can deliver innovative solutions and ensure your move is completed with the utmost precision, efficiency, and safety.
                    <br /><br />
                    We have the capacity to perform light rigging and have many years of experience under our belt to safely move your industrial assets.
                </>
        },
        'Lab & Medical Moving': {
            desc:
                <>
                    Rest assured, you can trust our highly trained, experienced crews to handle your high value equipment moving needs with meticulous care and diligence.
                    <br /><br />
                    As one of the premier full services facility movers in the DFW market, we are committed to successfully relocating your most sensitive items.
                </>
        },
        'IT Relocation': {
            desc:
                <>
                    In today’s fast paced world of technology, infrastructure downtime is not an option.
                    <br /><br />
                    Our team of highly experienced technicians can assist with packing and moving your data center or transferring any networking equipment, large or small.
                    <br /><br />
                    Our uniformed, background checked crews are available around the clock to deliver a speedy, yet smooth transition.
                </>
        },
        'Office & Building Decommission': {
            desc:
                <>
                    Downsizing or time to clean up?  Our all-inclusive services can help with any removal, liquidation, recycling, repurposing, or disposal needs you might have.
                    <br /><br />
                    Let the Superior team help provide an assessment of your unwanted items and manage the decommission process so you can focus on more important things.
                </>
        },
        'Warehousing & Storage': {
            desc:
                <>
                    Our secure warehouse is centrally located in DFW so we can custom store and deliver routes to best meet your customers’ needs.
                    <br /><br />
                    Let us help with any warehousing or storage needs.
                </>
        },
        /* Category - Installation  */
        'Hotel & Restaurant FF&E': {
            desc:
                <>
                    From small restaurants and custom designed boutique hotels to large convention size casino properties, Superior can warehouse, store, deliver and white glove install all furnishings, fixtures and equipment needed for a new property or partial remodel.
                    <br /><br />
                    We handle large and small scale projects, customizing our services to your ever changing needs.
                </>
        },
        'Student Housing Installation Experts': {
            desc:
                <>
                    We have consistently been a well-respected installer of dormitory furnishings throughout the U.S. for many
                    years.
                    <br /><br />
                    Our skilled crews install approximately 2 million pounds of furniture into new housing properties annually.
                    <br /><br />
                    We also provide extraction and disposal of old furnishings from the existing property if needed.
                    <br /><br />
                    All installation and extractions are performed with careful consideration to cost budgets and in the time frame allotted by our customer.
                </>
        },
        'Healthcare & Data Center Expansion': {
            desc:
                <>
                    Expanding current locations can be complex and cumbersome.  You’re in good hands with Superior.  We have participated in many expansions over the years, with some projects lasting several months.
                    <br /><br />
                    Our management team comes equipped with an array of knowledge and Superior is fully insured for any project.Our qualified moving and installation crews can be modified to fit your daily project needs if construction delays occur.
                </>
        },
        'Retail Relocation & Fixtures': {
            desc:
                <>
                    Let our professional team help with your store set up, fixture switch out or move out.
                    <br /><br />
                    Whether moving in new seasonal displays, storing product, or moving the entire store to a new location, we're here to assist every step of the way.
                </>
        },
        'Modular & Cubical Furniture Systems': {
            desc:
                <>
                    Since our beginning, we have taken pride in our innate ability to set up and reconfigure all types of modular furnishings.
                    <br /><br />
                    With today's modular systems constantly evolving our skillset has become more diverse to include full soundproof meeting and focus room installation.
                </>
        },
        'Professional Artwork': {
            desc:
                <>
                    Our skilled, professional team of art installers work closely with the client to hang artwork, décor, mirrors, interior partitions, and pre-determined wall systems safely, securely, and efficiently.
                    <br /><br />
                    We bring all tools needed for the job and can provide any necessary hardware if not shipped from the manufacturer. We will make sure all items are secure locked and soundly positioned to the desired elevations at the new property.
                </>
        },
        'Logistics': {
            desc:
                <>
                    Superior's experienced project management team can help with any logistical needs your company may have. <b>Planning</b>and <b>understanding</b> are <b>the essential keys to our success.</b> Let our dedicated, friendly staff help assess your needs and set up a plan that is carefully molded to your specific project. <b>Let us help with the following services:</b>
                    <ul>
                        <li>Order fulfillment</li>
                        <li>Project management</li>
                        <li>Asset management & liquidation</li>
                        <li>Trade shows</li>
                        <li>Electronics & sensitive equipment</li>
                        <li>Intra & Interstate transportation services</li>
                        <li>Blanket wrap</li>
                        <li>High value products (HVP)</li>
                        <li>Expedited delivery/ hot shot</li>
                        <li>Cross docking/storage</li>
                        <li>Advanced receiving</li>
                    </ul>
                </>
        },
        'Final Mile / White Glove': {
            desc:
                <>
                    As consumer online purchases skyrocket, choose Superior to ensure your product and your customer are in the best of hands.  Our friendly, uniformed, background checked delivery technicians will complete onsite assembly to the room of choice with debris and old item removal
                    <br /><br />
                    Our goal is to leave your customer happy, content, and excited to have their new purchase from you.  Let us work with you to customize a program that fulfills all your final mile challenges while providing a high-quality delivery service to your discerning customer.  Superior is ready and able to handle any elaborate, complex, or unforeseen needs that arise.
                    <ul>
                        <li>E-Commerce Home Delivery</li>
                        <li>Specialized White Glove</li>
                        <li>Shortened Delivery Time Windows</li>
                        <li>Assembly/ Disassembly</li>
                        <li>Electronics & sensitive equipment</li>
                        <li>Packaging & repackaging services</li>
                        <li>Intra & interstate transportation services</li>
                        <li>Blanket wrap</li>
                        <li>High value products (HVP)</li>
                        <li>Expedited delivery/ hot shot</li>
                    </ul>
                </>
        },
    }

    return (
        <ModalWindow
            className={isVisible ? 'lg visible' : ''}
            onClickClose={() => { setIsVisible(false) }}
        >
            <>
                <InfoWrapper>
                    <InfoTitle>{service}</InfoTitle>
                    <InfoParagraph>
                        {DictServicesInfo[service]?.desc}
                    </InfoParagraph>
                </InfoWrapper>
                <CallToAction
                    onClick={() => {
                        scroll.scrollToBottom(500);
                        setIsVisible(false)
                    }}

                >
                    Contact Us
                </CallToAction>
            </>
        </ModalWindow>
    )
}

export default ModalServices;
import styled from "@emotion/styled";
// Utilidades
import { mediaDevices } from "../../../utilities/mediaDevices.const";
import {
    kMediumGrey,
    kPrimaryColor,
} from "../../../utilities/pallete.const";
import Card from "../../common/card.styled";

export const WhyUsWrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    gap: 0;
    @media ${mediaDevices.tablet} {
        padding: 50px 40px;
        gap: 50px;
    }
    @media ${mediaDevices.laptop} {
        padding: 50px 10vw;
        gap: 80px;
    }
    @media ${mediaDevices.desktop} {
        padding: 50px 20vw;
    }
`;
export const TitleSection = styled.h2`
    text-align: center;
`;
export const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ${mediaDevices.tablet}{
        flex-direction: row;
    }
`;
export const CardItem = styled(Card)`
    border: ${kPrimaryColor} 2px solid;
    z-index: 1;
    justify-content: flex-start;
    margin: 20px 0;
    transition: all 0.25s;
    &:hover{
        transform: scale(1.02);
        box-shadow: 0px 5px 30px ${kMediumGrey};
    }
    &:first-of-type{
        margin-top: 70px;
    }
    &:last-of-type{
        margin-bottom: 30px;
    }
    @media ${mediaDevices.mobileL}{
        padding-top: 70px;
        margin: 45px 0;
    }
    @media  ${mediaDevices.tablet}{
        margin: 0 30px;
        &:first-of-type,
        &:last-of-type{
            margin: 0;
        }
    }
    @media ${mediaDevices.laptopS}{
        margin: 0 5vw;
    }
    @media ${mediaDevices.laptop}{
    }
`;
export const ImageContainer = styled.div`
    margin-bottom: 20px;
    img{
        max-height: 120px;
        width: 100%;
    }
    @media ${mediaDevices.mobileL}{
        img {
            max-height: 150px;
        }
    }
    @media ${mediaDevices.laptopS}{
        img {
        max-height: 180px;
        }
    }
    @media ${mediaDevices.laptop}{
        img {
        max-height: 220px;
        }
    }
`;
export const CardTitle = styled.h3`
    margin-bottom: 15px;
    color: ${kPrimaryColor};
    @media  ${mediaDevices.tablet}{
        margin-bottom: 25px;
    }
    @media ${mediaDevices.laptopS}{
        margin-bottom: 35px;
    }
`;
export const DesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4{
        font-family: var(--fontTitle);
        font-size: 3rem;
        margin-bottom: 0.5rem;
    }
`